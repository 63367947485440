import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';
import Subscribe from '../../components/Subscribe';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Kontakt" slug="contact"/>
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Kontakt</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button={false} />
        </div>

      </div>
      <p/>
     {/* <Subscribe/>*/} 
    </div>
  </Layout>
);

export default Contact;
