import { navigate } from "gatsby";
import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
const zip = (arr, ...arrs) => {
  return arr.map((val, i) => arrs.reduce((a, arr) => [...a, arr[i]], [val]));
}

const boxes = ["allgemeine Infos", "Fleischverkauf", "Erdbeeren"];

const Subscribe = (props) => {
  const [email, setEmail] = useState("");
  const [mail1, setMail1] = useState(""); // honeypot
  const [check, setCheck] = useState([true, true, true]);
  const { contactPostAddress } = props.data.site.siteMetadata;
  return (
    <div className="call">
      <form
        name="contact"
        method="post"
        action={contactPostAddress}
        onSubmit={(e) => {
          e.preventDefault();
          if (mail1 === '') {
            fetch(contactPostAddress, {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": "contact",
                email,
                mail1,
                check: zip(boxes, check),
              }),
            })
              .then(function(rawResponse) {
                return rawResponse.json();
              })
              .then(function(response) {
                console.log(response);
                if (response.result == "success") {
                  console.log("Form submission success");
                  navigate("/mail");
                } else {
                  console.error(response);
                  alert(
                    "Der Server hat mit einem Fehler geantwortet. Sorry dafür."
                  );
                }
              })
              .catch((error) => {
                console.error(error);
                alert(
                  "Fehler bei der Übertragung. Funktioniert die Internetverbindung?"
                );
              });
          }
        }}
      >
        <div className="call-box-top">
          <h2>Ich möchte informiert werden!</h2>
          Ich möchte eine Nachricht erhalten, wenn der nächste Verkaufstermin
          feststeht.
          <div className="call-email">
            <strong>Email: </strong>
            <input
              type="email"
              name="email"
              value={mail1}
              onChange={(e) => setMail1(e.target.value)}
              style={{ display: "none" }}
            />
            <input
              type="email"
              name="address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            {boxes.map(
              (name, index) => {
                return (
                  <div key={index}>
                    <strong>{`${name}: `} </strong>
                    <input
                      type="checkbox"
                      name="cb1"
                      checked={check[index]}
                      onChange={(e) => {
                        const items = [...check];
                        items[index] = e.target.checked;
                        setCheck(items);
                      }
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="call-box-bottom">
          <input
            type="submit"
            value="Los"
            id="submitButton"
            className="button"
          />
        </div>
      </form>
    </div>
  );
};

//export default Subscribe;

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              address
              addressTown
            }
            contactPostAddress
          }
        }
      }
    `}
    render={(data) => <Subscribe button={props.button} data={data} />}
  />
);
